import { SourceType } from "@/app/core/utils/enumDisplayRender";

export enum BreakdownType {
  Roll = "ROLL",
  Steer = "STEER",
  Brake = "BRAKE",
  Salvage = "SALVAGE",
  Runs = "RUNS",
}

export const BreakdownTypeDisplay: SourceType[] = [
  {
    value: BreakdownType.Roll,
    name: "Roll",
  },
  {
    value: BreakdownType.Steer,
    name: "Steer",
  },
  {
    value: BreakdownType.Brake,
    name: "Brake",
  },
  {
    value: BreakdownType.Salvage,
    name: "Salvage",
  },
  {
    value: BreakdownType.Runs,
    name: "Runs",
  },
];
