import { VehicleType } from "@/app/core/enums/VehicleType";
import { BreakdownType } from "@/app/core/enums/BreakdownType";
import { PaymentTypeEnum } from "@/app/core/enums/PaymentTypeEnum";
import { User } from "@/app/core/models/domain/User";
import { Location } from "@/app/core/models/domain/Location";

export class SavedSearch {
  id: number;
  userId: number;
  name: string;
  pickupRadius: number;
  pickupLocationId: number;
  destinationRadius: number;
  destinationLocationId: number;
  vehiclesTypes: VehicleType[];
  breakdowns: BreakdownType[];
  minVehicleCount: number;
  maxVehicleCount: number;
  minPaymentTotal: number;
  postedWithin: number;
  paymentTypes: PaymentTypeEnum[];
  user: User;
  readyForPickup: number;
  pickupLocation: Location;
  destinationLocation: Location;
}
